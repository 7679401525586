.tiedot-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;
    margin-bottom: 60px;
    font-size: 18px;

    span {
        display: flex;
        align-items: center;
        gap: 10px;
    }
}

.icon {
    font-size: 24px;
}

.tiedot-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    font-size: 18px;
    gap: 60px;

    .facebook-link {
        text-decoration: none;
        color: black;

        &:hover {
            text-decoration: underline;
        }
    }

    span {
        display: flex;
        align-items: center;
        gap: 10px;
        text-decoration: none;
    }
}

@media screen and (max-width: 768px) {
    .tiedot-row {
        flex-direction: column;
        gap: 30px;
    }

}

@media screen and (max-width: 680px) {
    .tiedot-row {
        flex-direction: column;
        gap: 30px;
    }

    .tiedot-column .facebook2 {
        text-align: center;
    }
}

@media screen and (max-width: 600px) {
    .tiedot-row {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        gap: 30px;
    }

    .tiedot-column {
        justify-content: center;
        align-items: center;
        font-size: 16px;

        .facebook {
            align-items: flex-start;
        }

        .facebook2 {
            text-align: center;
        }
    }

}

@media screen and (max-width: 320px) {
    .tiedot-row {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        gap: 30px;
    }

    .tiedot-column {
        justify-content: center;
        align-items: center;
        font-size: 16px;

        .facebook {
            align-items: flex-start;
        }

        .facebook2 {
            text-align: center;
        }
    }
}