//Navigaatio pysyy sivuston yläosassa
#header {
    background-color: var(--beige);
    height: 75px;
    position: fixed;
    top: 0;
    z-index: 999;

    .header-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    #logo {
        width: 150px;
    }

//Oikealla olevat linkit
    .header-links {
        display: flex;
        gap: 40px;
        font-size: 20px;
    }
    .link {
      color: #000;
      text-decoration: none;
      padding: 10px;
    }

    .header-links a:hover {
      background-color: var(--kontrasti);
      border-radius: 6px;
      color: white;
    }

/* Position and sizing of burger button */
.bm-burger-button {
    display: none;
    position: relative;
    width: 36px;
    height: 30px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: black;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: black;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    top: 0;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: var(--beige);
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    overflow: hidden !important;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1vh;
    color: #b8b7ad;
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
}

//Tabletti
@media screen and (max-width: 768px) {
    #header {
        .link {
            display: none;
        }

        .bm-burger-button {
            display: block;
        }
    }
}

//Iso puhelin esim. Iphone 12 Pro
@media screen and (max-width: 600px) {
    #header {
        .link {
            display: none;
        }

        .bm-burger-button {
            display: block;
        }
    }
}

//Pieni puhelin esim. Galaxy Fold
@media screen and (max-width: 320px) {
}
