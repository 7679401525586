.palvelu-grid {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 60px;
	margin-top: 60px;
}

.palvelu {
	width: 100%;
	display: flex;
	gap: 60px;
	align-items: center;

	.teksti {
		width: 55%;
	}
}

.palvelu-img {
	width: 45%;
	border: 2px solid var(--kontrasti);
	border-radius: 12px;
	box-shadow: 0 0 24px rgba(0,0,0,0.63);
	height: 250px;
	object-fit: cover;
	image-rendering: pixelated;
}

@media screen and (min-width: 2560px) {
	.palvelu-img {
		height: 350px;
	}
}

@media screen and (min-width: 3440px) {
	.palvelu-img {
		height: 450px;
	}

	.palvelu {
		.teksti {
			width: 60%;
		}

		.palvelu-img {
			width: 40%;
		}
	}

	.palvelu-grid {
		gap: 50px;
	}
}

@media screen and (max-width: 1160px) {
	.palvelu-grid {
		gap: 40px;
	}

	.palvelu {
		gap: 40px;
	}
}

@media screen and (max-width: 1024px) {
	.palvelu .teksti {
		font-size: 16px;
	}
}

@media screen and (max-width: 920px) {
	.palvelu-grid {
		gap: 40px;
	}

	.palvelu {
		gap: 30px;
	}
}

@media screen and (max-width: 768px) {
	.palvelu-img {
		width: 40%;
	}

	.palvelu .teksti {
		width: 60%;
	}
}

@media screen and (max-width: 680px) {
	.palvelu {
		flex-direction: column;

		.teksti {
			width: 100%;
		}
	}

	.palvelu-img {
		width: 100%;
		height: 200px;
	}

	.palvelu-img:first-of-type {
		object-position: center 30%;
	}

	.palvelu:last-of-type {
		flex-direction: column-reverse;
	}
}

@media screen and (max-width: 600px) {
	.palvelu-grid {
		margin-top: 40px;
	}
}

@media screen and (max-width: 320px) {
	.palvelu-img {
		height: 150px;
	}
}