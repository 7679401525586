#landing {
	padding: 0;
	width: 100vw;
	height: 665px;
	margin-top: 75px;
	overflow: hidden;
	position: relative;

	.content {
		height: 100%;
		position: absolute;
		z-index: 2;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 40px;
	}

	.teksti {
		color: #fff;
		text-align: center;
		width: 580px;
		text-shadow: 3px 6px 6px rgba(0,0,0,0.27);
	}
}

#landing-head {
	color: #fff !important;
	text-shadow: 3px 6px 6px rgba(0,0,0,0.27);
	width: 550px;
}

.landing-bg {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 0;
	object-fit: cover;
}

.bg-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 1;
	background-color: #000;
	opacity: 0.4;
}

.landing-buttons {
	display: flex;
	gap: 25px;
	align-items: center;
	justify-content: center;
}

.btn {
	width: max-content;
	padding: 8px 24px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	text-decoration: none;
	color: #fff;
	border: 1px solid var(--kontrasti);
	background-color: transparent;
	transition: 0.2s;

	&:hover {
		transform: translateY(-2px);
		filter: brightness(110%);
	}
}

.btn_red {
	background-color: var(--kontrasti);
}

@media screen and (min-width: 2560px) {
	#landing {
		height: 800px;
	}

	#landing .content {
		gap: 60px;
	}

	#landing-head {
		font-size: 48px;
	}

	#landing .teksti {
		font-size: 24px;
		width: 680px;
	}

	.btn {
		font-size: 18px;
	}
}

@media screen and (min-width: 3440px) {
	#landing {
		height: 1000px;
	}
	
	#landing-head {
		font-size: 64px;
	}

	#landing .teksti {
		font-size: 30px;
		width: 830px;
	}

	.landing-buttons {
		gap: 40px;
	}

	.btn {
		font-size: 22px;
	}
}

@media screen and (max-width: 768px) {
	.landing-buttons {
		gap: 20px;
	}

	#landing {
		height: 700px !important;

		.content {
			gap: 60px;
		}

		.teksti {
			width: 500px;
		}
	}
}

@media screen and (max-width: 600px) {
	#landing {
		height: calc(100vh - 75px) !important;

		.content {
			gap: 50px;
		}

		.teksti {
			width: 100%;
		}
	}

	.landing-buttons {
		gap: 15px;
	}

	#landing-head {
		width: 100%;
		font-size: 26px;
	}
}

@media screen and (max-width: 320px) {
	.btn {
		padding: 6px 18px;
	}
}