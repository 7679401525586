#galleria {
    background-color: var(--beige);

    * {
        border: none;
        outline: none;
    }

    #otsikko-galleria {
        margin-bottom: 60px;
    }

    .slick-list {
        padding: 50px;
    }

    .slider-container {
        width: 100%;

        .slick-slide { //Animaatio, jossa keskimmäinen kuva isompi
            transition: 1s transform;
        }

        .slick-center { //Animaatio, jossa keskimmäinen kuva isompi
            transform: scale(1.2);
            transition: 1s;
        }

        .img-body {
            display: flex;
            justify-content: center;
            margin: 0 auto;
            object-fit: cover;
            width: 100%;
            height: 100%;
            padding: 0 20px;
            transition: 1s;
            border-radius: 20px;
        }

        .image {
            width: 100%;
            height: auto;
            padding: 6px;
            border-radius: 20px;
            transition: 1s;
        }
    }

    .slick-prev:before, .slick-next:before { //Nuolet
        color: black;
    }

    //Navigaatio pallot
    .slick-dots {
        position: relative;
        margin-top: -10px;
        margin-bottom: 40px;
    }

    .dots {

        button:before {
            content: '';
            background-color: var(--kontrasti);
            border-radius: 50%;
            width: 18px;
            height: 18px;
            opacity: 1;
        }

        .slick-active button:before {
            border: 2px solid #000;
            width: 16px;
            height: 16px;
            background-color: #821B1B;
        }
    }
}

@media screen and (min-width: 1440px) {
    .slick-list {
        padding: 60px 50px !important;
    }
}

@media screen and (min-width: 2560px) {
    #galleria .slider-container {
        .img-body {
            padding: 0 40px !important;
            border-radius: 20px;
        }
    }
}

@media screen and (min-width: 3440px) {
    #galleria .slider-container {
        .img-body {
            padding: 0 40px !important;
            border-radius: 20px;
        }
    }
}