#footer {
	padding: 60px 0;
	background-color: var(--beige);
}

.footer-top {
	width: 100%;
}

.footer-grid {
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.footer-item {
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.footer-head {
	margin-bottom: 15px;
}

.footer-logo {
	width: 230px;
}

.intro-teksti {
	width: 380px;
}

.footer-bottom {
	width: 100%;
	margin-top: 60px;
}

.footer-link {
	color: #000;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

h4.teksti {
	margin-bottom: 15px;
}

.teksti {
	display: flex;
	align-items: center;
}

.f-icon {
	width: 20px;
	height: 20px;
	font-size: 20px;
	margin-right: 8px;
}

.footer-list {
	display: flex;
	flex-direction: row;
	gap: 12px;
}

.list-item {
	text-decoration: none;
	color: #000;
	margin-right: 8px;

	&:hover {
		text-decoration: underline;
	}
}

@media screen and (min-width: 2560px) {
	.intro-teksti {
		width: 560px;
	}

	h4.teksti {
		font-size: 28px;
		font-weight: 550;
	}

	.list-item {
		font-size: 24px;
		margin-right: 16px;
	}
}

@media screen and (min-width: 3440px) {
	.intro-teksti {
		font-size: 24px;
		width: 560px;
	}
}

@media screen and (max-width: 1160px) {
	.footer-grid {
		display: grid;
		grid-template-columns: repeat(3, auto);
		row-gap: 48px;
	}
}

@media screen and (max-width: 920px) {
	.footer-grid {
		display: grid;
		grid-template-columns: repeat(2, auto);
		row-gap: 48px;
	}
}

@media screen and (max-width: 680px) {
	.intro-teksti {
		width: 320px;
	}
}


@media screen and (max-width: 600px) {
	#footer {
		padding: 40px 0;
	}

	.footer-grid {
		display: flex;
		flex-direction: column;
		row-gap: auto;
		gap: 40px;
	}
	
	.footer-list {
		margin-left: 16px;
		gap: 5px;
		flex-direction: column;
	}
}

@media screen and (max-width: 320px) {
	.intro-teksti {
		width: 100%;
	}
}